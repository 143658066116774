export enum ViewType {
    People = 'people',
    Category = 'category',
    CategoryItem = 'categoryItem',
    Search = 'search',
    Favorites = 'favorites'
}

export enum SettingsTab {
    General = 'general',
    Categories = 'categories',
    Columns = 'columns',
    Filters = 'filters',
    Security = 'security',
    Consent = 'consent',
    DataSources = 'dataSources'
}

export interface ConfirmationDialogModel {
    title: string;
    message: string;
}

export interface DataSource {
    /*
    public int Id { get; set; }
    public string TenantAccountId { get; set; } = null!;
    public string Name { get; set; } = null!;
    public string? Description { get; set; }
    public string? ChartId { get; set; }
    public DateTime Created { get; set; }
    public string CreatedBy { get; set; } = null!;
    */
    //convert the commented above .net to the below typescript
    id: number;
    tenantAccountId?: string;
    name: string;
    description?: string;
    chartId: string;
    created?: Date;
    createdBy?: string;
    lastModified?: Date;
    lastModifiedBy?: string;
    isDefault?: boolean;
    shouldLockRegularUsers?: boolean;
}

export type TDHttpError = {
    message: string;
    status: number;
    error: string;
};
