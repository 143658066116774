import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { DataSourceType } from '../services/data-service.factory';
import {
    Category,
    CustomizedCategoryItem,
    DefaultDirectoryUserColumnSettings,
    Filter,
    UserColumnSettings
} from './categories';
import * as dataActions from './data.actions';
import * as settingsActions from '../settings/settings.actions';
import * as integrityActions from '../integrity/integrity.actions';
import {
    CategoryItemsViewMode,
    DirectoryUser,
    GroupByField,
    RefreshTimestampKey,
    SortField,
    SortOrder,
    UsersViewMode
} from './data.models';
import { MsalErrors } from '../auth/auth.models';
import { DirectoryUserModification } from '../integrity/integrity.models';
import { SettingsType } from '../settings/settings.models';

export const dataFeatureKey = 'data';

export interface State {
    users: DirectoryUser[];
    filteredUsers: DirectoryUser[];
    categories: Category[];
    dataSourceType: DataSourceType;
    progress: number;
    usersPageNumber: number;
    usersPageSize: number;
    usersViewMode: UsersViewMode;
    categoryItemsViewModes: { [fieldName: string]: CategoryItemsViewMode };
    categorySortFields: { [fieldName in keyof Partial<DirectoryUser>]: string };
    categorySortOrders: { [fieldName in keyof Partial<DirectoryUser>]: SortOrder };
    expandedCategoriesIds: number[];
    openedModalNames: string[];
    openedModalOptions: any;
    sortField: SortField;
    sortOrder: SortOrder;
    groupBy: GroupByField;
    isSavingFromDialog: boolean;
    savedCategories: Category[];
    savedCategoryItems: CustomizedCategoryItem[];
    hasLoadedDataFromDb: boolean;
    lastDataRefreshDateString: string;
    hasFailedToGetDirectoryData: boolean;
    userColumnSettings?: UserColumnSettings;
    userModifications: DirectoryUserModification[];
    filters: Filter[];
    favorites: string[];
    hasLoadDataFromDbFailed: boolean;
    selectedDataSourceId?: number;
    selectedDataSourceChartId?: string;
    isFromToc?: boolean;
    hasDataSourceBeenSet: boolean;
    filterLetter?: string;
}

export const initialState: State = {
    users: [],
    filteredUsers: [],
    categories: [],
    progress: 100,
    dataSourceType: DataSourceType.Demo,
    usersViewMode: UsersViewMode.Cards,
    categoryItemsViewModes: {},
    categorySortFields: {},
    categorySortOrders: {},
    usersPageNumber: 1,
    usersPageSize: 20,
    expandedCategoriesIds: [],
    openedModalNames: [],
    openedModalOptions: null,
    sortField: 'firstName',
    sortOrder: SortOrder.Asc,
    groupBy: null,
    isSavingFromDialog: false,
    savedCategories: [],
    savedCategoryItems: [],
    hasLoadedDataFromDb: false,
    lastDataRefreshDateString: '',
    hasFailedToGetDirectoryData: false,
    userColumnSettings: DefaultDirectoryUserColumnSettings,
    userModifications: [],
    filters: [],
    favorites: [],
    hasLoadDataFromDbFailed: false,
    selectedDataSourceId: null,
    selectedDataSourceChartId: null,
    isFromToc: null,
    hasDataSourceBeenSet: false,
    filterLetter: null
};

export const reducer = createReducer<State, Action>(
    initialState,
    on(dataActions.loadUsersSuccess, dataActions.reloadUsersSuccess, (state, { users, dataSourceType }) => ({
        ...state,
        users,
        dataSourceType
    })),
    on(dataActions.loadIndividualUsersSuccess, (state, { users, dataSourceType }) => ({
        ...state,
        users,
        dataSourceType
    })),
    on(dataActions.loadUsersProgress, (state, { progress }) => ({ ...state, progress })),
    on(dataActions.setUsersPageNumber, (state, { pageNumber }) => ({ ...state, usersPageNumber: pageNumber })),
    //on(routerNavigatedAction, (state) => ({ ...state, usersPageNumber: 1 })),
    on(dataActions.setUsersPageSize, (state, { pageSize }) => ({ ...state, usersPageSize: pageSize })),
    on(dataActions.setUsersViewMode, (state, { viewMode }) => ({ ...state, usersViewMode: viewMode })),
    on(dataActions.setCategoryItemsViewMode, (state, { categoryChosenViewMode }) => ({
        ...state,
        categoryItemsViewModes: {
            ...state.categoryItemsViewModes,
            [categoryChosenViewMode.category.fieldName]: categoryChosenViewMode.viewMode
        }
    })),
    on(dataActions.setCategorySortField, (state, { categoryChosenSortField }) => ({
        ...state,
        categorySortFields: {
            ...state.categorySortFields,
            [categoryChosenSortField.category.fieldName]: categoryChosenSortField.sortField
        }
    })),
    on(dataActions.setCategorySortOrder, (state, { categoryChosenSortOrder }) => ({
        ...state,
        categorySortOrders: {
            ...state.categorySortOrders,
            [categoryChosenSortOrder.category.fieldName]: categoryChosenSortOrder.sortOrder
        }
    })),
    on(dataActions.setSortField, (state, { sortField }) => ({ ...state, sortField })),
    on(dataActions.setSortOrder, (state, { sortOrder }) => ({ ...state, sortOrder })),
    on(dataActions.setGroupBy, (state, { groupBy }) => ({ ...state, groupBy })),
    on(dataActions.loadDataFromDbSuccess, (state, { dataFromDb }) => ({
        ...state,
        users: dataFromDb.users,
        filteredUsers: dataFromDb.filteredUsers,
        categories: dataFromDb.categories,
        savedCategories: dataFromDb.savedCategories,
        savedCategoryItems: dataFromDb.savedCategoryItems,
        userColumnSettings: dataFromDb.userColumnSettings ?? DefaultDirectoryUserColumnSettings,
        userModifications: dataFromDb.userModifications,
        filters: dataFromDb.filters,
        favorites: dataFromDb.favorites,
        hasLoadedDataFromDb: true,
        dataSourceType: dataFromDb.dataSourceType,
        lastDataRefreshDateString: localStorage.getItem(RefreshTimestampKey) ?? ''
    })),
    on(dataActions.hasRefreshedData, (state) => ({
        ...state,
        lastDataRefreshDateString: localStorage.getItem(RefreshTimestampKey) ?? ''
    })),
    on(dataActions.loadDataFromDbFailure, (state, { error }) => {
        if (
            error?.errorCode === MsalErrors.userCancelled ||
            error?.errorCode === MsalErrors.consentRequired ||
            error?.errorCode === MsalErrors.accessDenied
        ) {
            return {
                ...state,
                hasFailedToGetDirectoryData: true
            };
        }
        return state;
    }),
    on(dataActions.toggleExpandCategory, (state, { id }) => ({
        ...state,
        expandedCategoriesIds: state.expandedCategoriesIds.includes(id)
            ? state.expandedCategoriesIds.filter((x) => x !== id)
            : [...state.expandedCategoriesIds, id]
    })),
    on(dataActions.dialogHide, (state) => ({
        ...state,
        openedModalNames: state.openedModalNames.slice(0, state.openedModalNames.length - 1)
    })),
    on(dataActions.dialogShow, (state, { dialog, options }) => ({
        ...state,
        openedModalNames: [...state.openedModalNames, dialog],
        openedModalOptions: options
    })),
    on(dataActions.saveCategoryItem, (state) => ({ ...state, isSavingFromDialog: true })),
    on(dataActions.saveCategoryItemSuccess, (state, { savedCategoryItems }) => ({
        ...state,
        savedCategoryItems,
        isSavingFromDialog: false
    })),
    on(
        dataActions.rebuildCategoriesFromDbSuccess,
        dataActions.rebuildCategoriesFromDbWithUsersSuccess,
        (state, { dataFromDb }) => ({
            ...state,
            users: dataFromDb.users,
            filteredUsers: dataFromDb.filteredUsers,
            categories: dataFromDb.categories,
            savedCategories: dataFromDb.savedCategories,
            savedCategoryItems: dataFromDb.savedCategoryItems,
            userColumnSettings: dataFromDb.userColumnSettings ?? DefaultDirectoryUserColumnSettings,
            userModifications: dataFromDb.userModifications,
            filters: dataFromDb.filters,
            favorites: dataFromDb.favorites,
            hasLoadedDataFromDb: true,
            dataSourceType: dataFromDb.dataSourceType,
            lastDataRefreshDateString: localStorage.getItem(RefreshTimestampKey) ?? ''
        })
    ),
    on(dataActions.toggleFavorite, (state, { id }) => ({
        ...state,
        favorites: state.favorites.includes(id) ? state.favorites.filter((x) => x !== id) : [...state.favorites, id]
    })),
    on(dataActions.toggleFavoriteSuccess, (state, { favorites }) => ({
        ...state,
        favorites
    })),
    on(dataActions.loadDataFromDbFailure, dataActions.reloadUsersFailure, (state) => ({
        ...state,
        hasLoadDataFromDbFailed: true,
        progress: 100
    })),
    on(dataActions.loadDataFromDbSuccess, (state) => ({
        ...state,
        hasLoadDataFromDbFailed: false
    })),
    on(dataActions.setDataSource, (state, { dataSourceId, chartId, isFromToc }) => ({
        ...state,
        selectedDataSourceId: dataSourceId,
        selectedDataSourceChartId: chartId,
        isFromToc,
        hasDataSourceBeenSet: true
    })),
    on(dataActions.setFilterLetter, (state, { filterLetter }) => ({
        ...state,
        usersPageNumber: 1,
        filterLetter
    })),
    on(settingsActions.saveUserModificationsSuccess, (state, { response }) => ({
        ...state,
        userModifications: response[SettingsType.Modifications]?.value
    }))
    // on(integrityActions.saveUserModificationsSuccess, (state, { modifications }) => ({
    //     ...state,
    //     userModifications: modifications
    // }))
);

export const selectUsersRaw = (state: State) => state.users;
export const selectUsersUnsorted = (state: State) => state.filteredUsers;
export const selectProgress = (state: State) => state.progress;
export const selectDataSourceType = (state: State) => state.dataSourceType;
export const selectUsersPageNumber = (state: State) => state.usersPageNumber;
export const selectUsersPageSize = (state: State) => state.usersPageSize;
export const selectUsersViewMode = (state: State) => state.usersViewMode;
export const selectCategoryItemsViewModes = (state: State) => state.categoryItemsViewModes;
export const selectCategorySortFields = (state: State) => state.categorySortFields;
export const selectCategorySortOrders = (state: State) => state.categorySortOrders;
export const selectExpandedCategoriesIds = (state: State) => state.expandedCategoriesIds;
export const selectSortField = (state: State) => state.sortField;
export const selectSortOrder = (state: State) => state.sortOrder;
export const selectGroupBy = (state: State) => state.groupBy;
export const selectIsSavingFromDialog = (state: State) => state.isSavingFromDialog;
export const selectHasLoadedDataFromDb = (state: State) => state.hasLoadedDataFromDb;
export const selectCategories = (state: State) => state.categories;
export const selectOpenedModalNames = (state: State) => state.openedModalNames;
export const selectOpenedModalOptions = (state: State) => state.openedModalOptions;
export const selectSavedCategories = (state: State) => state.savedCategories;
export const selectSavedCategoryItems = (state: State) => state.savedCategoryItems;
export const selectHasFailedToGetDirectoryData = (state: State) => state.hasFailedToGetDirectoryData;
export const selectUserColumnSettings = (state: State) => state.userColumnSettings;
export const selectUserModifications = (state: State) => state.userModifications;
export const selectFilters = (state: State) => state.filters;
export const selectFavorites = (state: State) => state.favorites;
export const selectHasLoadDataFromDbFailed = (state: State) => state.hasLoadDataFromDbFailed;
export const selectSelectedDataSourceId = (state: State) => state.selectedDataSourceId;
export const selectSelectedDataSourceChartId = (state: State) => state.selectedDataSourceChartId;
export const selectIsFromToc = (state: State) => state.isFromToc;
export const selectHasDataSourceBeenSet = (state: State) => state.hasDataSourceBeenSet;
export const selectLastDataRefreshDateString = (state: State) => state.lastDataRefreshDateString;
export const selectFilterLetter = (state: State) => state.filterLetter;

export const selectUsersRawButFiltered = createSelector(selectUsersRaw, selectUsersUnsorted, (users, filteredUsers) =>
    users.filter((user) => filteredUsers.map((x) => x.id).includes(user.id))
);

export const selectMergedCategories = createSelector(
    selectCategories,
    selectSavedCategoryItems,
    (categories, savedCategoryItems) =>
        categories.map<Category>((category) => ({
            ...category,
            items: category?.items?.length
                ? category.items
                      .map((item) => {
                          const savedItem = savedCategoryItems.find(
                              (x) => x.fieldName === item.fieldName && x.fieldValue === item.fieldValue
                          );
                          if (!savedItem) {
                              return item;
                          }
                          return {
                              ...item,
                              name: savedItem.name,
                              patternUrl: savedItem.patternUrl ?? item.patternUrl,
                              pictureUrl: savedItem.pictureUrl ?? item.pictureUrl,
                              description: savedItem.description ?? item.description
                          };
                      })
                      .sort((a, b) => {
                          const sortField = category.defaultSortField ?? 'name';
                          let sortOrder: SortOrder = false ? SortOrder.Desc : SortOrder.Asc;
                          sortOrder = category.defaultSortOrder ?? SortOrder.Desc;

                          const aValue = a[sortField]?.toLowerCase();
                          const bValue = b[sortField]?.toLowerCase();

                          if (aValue < bValue) {
                              return sortOrder === SortOrder.Asc ? -1 : 1;
                          }
                          if (aValue > bValue) {
                              return sortOrder === SortOrder.Asc ? 1 : -1;
                          }
                          return 0;
                      })
                      .filter((item, index, self) => {
                          const uniqueIdentifier = item.fieldName + item.fieldValue;
                          const lowerCaseIdentifier = uniqueIdentifier.toLowerCase();
                          return (
                              self.findIndex(
                                  (i) => (i.fieldName + i.fieldValue).toLowerCase() === lowerCaseIdentifier
                              ) === index
                          );
                      })
                : []
        }))
);
