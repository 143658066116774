import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as dataActions from '../../data/data.actions';

@Component({
    selector: 'dir-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    isTeamsChannel: boolean = false;
    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.pipe(select(fromRoot.selectTeamsEnvironment)).subscribe((env) => {
            if (env != null) {
                this.isTeamsChannel = env.isTeamsChannel;
            } else {
                this.isTeamsChannel = false;
            }
        });
    }
}
