import { DataSource } from '../app.models';
import { TenantProfile, AccountInfo } from '@azure/msal-browser';

export interface TenantAccount {
    id: string;
    subscriptionStatus: SubscriptionStatus;
    userPermission: UserPermission;
    dataSources: DataSource[];
}

export interface UserPermission {
    userAccountId: string;
    permissionType: UserPermissionType;
}

export interface TeamsEnvironment {
    isTeams: boolean;
    isTeamsChannel: boolean;
    isTeamsMobile: boolean;
    isOutlook: boolean;
    teamsTabUrl: string;
}

export enum MsalErrors {
    userCancelled = 'user_cancelled',
    consentRequired = 'consent_required',
    accessDenied = 'access_denied',
    popupBlocked = 'popup_window_error'
}

export enum SubscriptionStatus {
    Trial = 0,
    Subscribed = 1,
    Expired = 2,
    Lapsed = 3
}

export enum UserPermissionType {
    Standard = 0,
    Admin = 1
}

export type ModifiedAccountInfo = Omit<AccountInfo, 'tenantProfiles'> & {
    tenantProfiles: TenantProfile[];
};
