import { select, Store } from '@ngrx/store';
import { forkJoin, Observable, of, switchMap, tap } from 'rxjs';
import { DirectoryUser, GraphUser, graphUserToDirectoryUser } from '../data/data.models';
import { DataSourceType } from './data-service.factory';
import { DataServiceResponse, IDataService } from './data.service';
import { AppDB } from './db.service';
import demoData from './demo-data.json';
import * as fromRoot from '../reducers';
import { settingsFeature } from '../settings/settings.reducer';

const STORAGE_PREFIX = 'https://storagecityimages.blob.core.windows.net/images/demoimages';

export class DemoDataService implements IDataService {
    constructor(private store: Store, private db: AppDB) {}
    reloadData(): Observable<DataServiceResponse> {
        return forkJoin([
            this.db.clearUsers(),
            this.db.clearCategories(),
            this.db.clearUserPhotos()
        ]).pipe(switchMap(() => this.getData()));
    }
    getData(): Observable<DataServiceResponse> {
        console.log('demo get data');
        const demoUsersData = demoData.demoData as Partial<GraphUser>[];
        const demoUsers = demoUsersData.map((user) => {
            let graphUser: Partial<GraphUser> = {
                id: '',
                displayName: '',
                jobTitle: '',
                department: '',
                city: '',
                country: '',
                mail: '',
                mobilePhone: '',
                state: '',
                userPrincipalName: '',
                faxNumber: '',
                givenName: '',
                surname: '',
                officeLocation: ''
            };
            graphUser = { ...graphUser, ...user };
            return graphUser;
        });

        return this.store.pipe(
            select(settingsFeature.selectDisplayNameSplit),
            switchMap((displayNameSplit) => {
                console.log('demo get data switch map');
                return of({
                    users: demoUsers
                        .map((user) => graphUserToDirectoryUser({ ...user }, displayNameSplit))
                        .map((user) => ({ ...user, pictureUrl: `${STORAGE_PREFIX}/${user.pictureUrl}` })),
                    dataSourceType: DataSourceType.Demo,
                    isRefreshNeeded: false
                });
            }),
            tap(() => console.log('demo get data tap'))
        );
    }
    getIndividualUsersData(users: DirectoryUser[]) {
        return of([]);
    }
    getDataSourceType(): DataSourceType {
        return DataSourceType.Demo;
    }
}
