import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as dataActions from '../../data/data.actions';
import * as AuthActions from '../../auth/auth.actions';
import * as uiActions from '../../ui/ui.actions';
import { BrowserService } from '../../services/browser-service';
import { Observable, Subject, combineLatest, distinctUntilChanged, map, takeUntil, withLatestFrom } from 'rxjs';
import * as fromRoot from '../../reducers';
import { uiFeature } from 'src/app/ui/ui.reducer';
import { environment } from '../../../environments/environment';
import { FeatureService } from '../services/feature.service';
import { Feature } from './features/features.models';
import { Router } from '@angular/router';

@Component({
    selector: 'dir-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject();

    user$: Observable<any>;
    userObjectId: string = '';
    userName: string = '';
    clientId = environment.clientId;
    online: boolean = true;
    isTeamsChannel: boolean = false;
    isTeamsMobile: boolean = false;
    isTeams: boolean = false;
    teamsTabUrl = '';
    isDemo$: Observable<boolean>;
    hostedInTOC: boolean = false;
    isDirectoryAvailable$: Observable<boolean>;
    constructor(
        private store: Store,
        private browserService: BrowserService,
        private featureService: FeatureService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.browserService.isOnline$.pipe(takeUntil(this.unsubscribe$)).subscribe((online) => {
            this.online = online;
        });

        this.isDemo$ = this.store.pipe(select(fromRoot.selectIsDemo));

        this.isDirectoryAvailable$ = combineLatest([
            this.store.pipe(select(fromRoot.selectHasFailedToGetDirectoryData)),
            this.featureService.hasFeature(Feature.Directory),
            this.isDemo$
        ]).pipe(
            takeUntil(this.unsubscribe$),
            map(([hasFailedToGetDirectoryData, hasFeature, isDemo]) => {
                return !hasFailedToGetDirectoryData || hasFeature || isDemo;
            }),
            distinctUntilChanged()
        );

        this.user$ = this.store.pipe(select(fromRoot.selectUser));
        this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
            if (user) {
                this.userName = user.username;
                this.userObjectId = user.localAccountId;
            }
        });

        this.store.pipe(select(fromRoot.selectTeamsEnvironment)).subscribe((env) => {
            if (env != null) {
                this.isTeamsChannel = env.isTeamsChannel;
                this.teamsTabUrl = env.teamsTabUrl;
                this.isTeamsMobile = env.isTeamsMobile
                this.isTeams = env.isTeams;
            } else {
                this.isTeamsChannel = false;
                this.isTeamsMobile = false;
                this.isTeams = false;
            }
        });

        this.store
            .select(uiFeature.selectHostedInTOC)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((hostedInTOC) => {
                this.hostedInTOC = hostedInTOC;
            });
    }

    onReload() {
        this.store.dispatch(dataActions.reloadUsersTopbar());
    }

    onLogout() {
        this.store.dispatch(AuthActions.msalLogout());
    }

    onRefresh() {
        document.location.reload();
    }

    openDrawer() {
        this.store.dispatch(uiActions.drawerOpen());
    }

    goHome() {
        this.router.navigate([this.teamsTabUrl], { queryParams: { teamstab: null }, queryParamsHandling: 'merge' });
    }

    onThemeChange(theme: 'light' | 'dark' | 'auto') {
        this.store.dispatch(uiActions.setTheme({ theme }));
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
