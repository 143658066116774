<div *ngIf="category" class="menu-item" [class.here]="rladepartments.isActive" [class.show]="isExpanded">
    <a class="menu-link" routerLink="/directory/{{category.slug}}" routerLinkActive="here"
        #rladepartments="routerLinkActive">
        <span class="menu-title" [innerHTML]="category.name"></span>
        <button class="btn btn-small btn-light" (click)="toggle($event)">
            <!-- <i *ngIf="!isExpanded" class="bi bi-plus-lg"></i>
            <i *ngIf="isExpanded" class="bi bi-dash-lg"></i> -->
            <span class="menu-arrow"></span>
        </button>
    </a>
    <div *ngIf="isExpanded" [@slideDownUp]="clicked" class="menu-sub menu-sub-accordion menu-active-bg show">
        <div class="menu-item" *ngFor="let item of category.items">
            <a class="menu-link" routerLink="/directory/{{category.slug}}/{{item.name}}" routerLinkActive="active">
                <span class="menu-title-sm" [innerHTML]="item.name"></span>
            </a>
        </div>
    </div>
</div>
