import { createAction, props } from '@ngrx/store';
import { DirectoryUser } from '../data/data.models';

export const drawerOpen = createAction('[UI] Open drawer');
export const drawerClose = createAction('[UI] Close drawer');

export const appEntryPointSearchPage = createAction('[UI] App entry point search page');

export const selectDeselectUser = createAction('[UI] Select or Deselect DirectoryUser', props<{ user: DirectoryUser }>());
export const clearSelectedUsers = createAction('[UI] Clear Selected Users');
export const openDeepLink = createAction('[UI] Open Deep Link', props<{ url: string }>());

export const hostedInTOC = createAction('[UI] Hosted in TOC');
export const hostedInSharePoint = createAction('[UI] Hosted in SharePoint');
export const editModeInSharePoint = createAction('[UI] Edit mode in SharePoint');
export const setSharePointStartingUrl = createAction('[UI] Set SharePoint Starting Url', props<{ url: string }>());

export const setTheme = createAction('[UI] Set Theme', props<{ theme: 'light' | 'dark' | 'auto' }>());
