import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject, filter, takeUntil } from 'rxjs';
import * as fromRoot from '../../reducers';
import { DataSource } from '../../app.models';
import { DefaultAzureSourceChartId } from '../../utils/data-source-helper';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

@Component({
    selector: 'dir-data-source-widget',
    templateUrl: './data-source-widget.component.html',
    styleUrl: './data-source-widget.component.scss'
})
export class DataSourceWidgetComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject();
    tenant$ = this.store.pipe(
        select(fromRoot.selectTenantAccount),
        filter((x) => x != null)
    );
    fromToc$ = this.store.pipe(select(fromRoot.selectIsFromToc));
    dataSources: DataSource[] = [];
    selectedSourceChartId: string = null;
    selectedDataSource?: DataSource = null;
    DefaultAzureSourceChartId = DefaultAzureSourceChartId;
    isAdmin = false;
    lastDataRefreshed?: dayjs.Dayjs = null;
    lastDataRefreshedString = '';
    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store
            .pipe(select(fromRoot.selectSelectedDataSourceChartId), takeUntil(this.unsubscribe$))
            .subscribe((x) => {
                this.selectedSourceChartId = x;
                this.selectedDataSource = this.dataSources.find((y) => y.chartId === this.selectedSourceChartId);
            });
        this.tenant$.pipe(takeUntil(this.unsubscribe$)).subscribe((tenant) => {
            this.dataSources = tenant.dataSources;
            this.selectedDataSource = this.dataSources.find((y) => y.chartId === this.selectedSourceChartId);
            //this.isAdmin = tenant.userPermission
        });
        this.store.pipe(select(fromRoot.selectUserIsAdmin), takeUntil(this.unsubscribe$)).subscribe((isAdmin) => {
            this.isAdmin = isAdmin;
        });
        this.store
            .pipe(
                select(fromRoot.selectLastDataRefreshDateString),
                takeUntil(this.unsubscribe$),
                filter((x) => x !== '')
            )
            .subscribe((date) => {
                //console.warn('SYNC date update');
                this.lastDataRefreshed = dayjs(date);
                dayjs.extend(relativeTime);
                this.lastDataRefreshedString = this.lastDataRefreshed.fromNow();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
