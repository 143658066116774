import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { SubscriptionStatus, TeamsEnvironment, TenantAccount, UserPermissionType } from './auth.models';
import { AccountInfo } from '@azure/msal-browser';

export const authFeatureKey = 'auth';

export interface State {
    user: AccountInfo;
    isTeams: boolean;
    teamsEnvironment: TeamsEnvironment;
    isDemo: boolean;
    tenantAccount: TenantAccount;
    isTeamsInteractionInProgress: boolean;
}

export const initialState: State = {
    user: null,
    isTeams: false,
    teamsEnvironment: null,
    isDemo: false,
    tenantAccount: null,
    isTeamsInteractionInProgress: false
};

export const reducer = createReducer(
    initialState,
    on(AuthActions.setIsTeams, (state, action) => ({ ...state, isTeams: action.isTeams })),
    on(AuthActions.setTeamsEnvironment, (state, { teamsEnvironment }) => ({
        ...state,
        teamsEnvironment: state.teamsEnvironment ? { ...state.teamsEnvironment, ...teamsEnvironment } : teamsEnvironment
    })),
    on(AuthActions.msalLoginSuccess, AuthActions.msalLoadLoggedInUser, (state, { data }) => ({
        ...state,
        user: data
    })),
    on(AuthActions.loadTenantSuccess, (state, { tenantAccount }) => ({ ...state, tenantAccount })),
    on(AuthActions.setIsDemo, (state, { isDemo }) => ({ ...state, isDemo })),
    on(AuthActions.startTeamsInteraction, (state) => ({ ...state, isTeamsInteractionInProgress: true })),
    on(AuthActions.endTeamsInteraction, (state) => ({ ...state, isTeamsInteractionInProgress: false }))
);

export const selectUser = (state: State) => state.user;
export const selectIsTeams = (state: State) => state.isTeams;
export const selectTeamsEnvironment = (state: State) => state.teamsEnvironment;
export const selectTenantAccount = (state: State) => state.tenantAccount;
export const selectIsDemo = (state: State) => state.isDemo;
export const selectSubscriptionStatus = (state: State) =>
    state.tenantAccount ? state.tenantAccount.subscriptionStatus : SubscriptionStatus.Trial;
export const selectUserObjectId = (state: State) => (state.user ? (state.user.localAccountId as string) : '');
export const selectUserIsAdmin = (state: State) => {
    //console.warn('selectUserIsAdmin', state.tenantAccount);
    return state.tenantAccount ? state.tenantAccount.userPermission.permissionType === UserPermissionType.Admin : false;
};
export const selectIsTeamsInteractionInProgress = (state: State) => state.isTeamsInteractionInProgress;
