import { createFeature, createReducer, on } from '@ngrx/store';
import { ReportResult } from './checks/report-result';
import * as integrityActions from './integrity.actions';
import * as settingsActions from '../settings/settings.actions';
import { ReportConfigData } from './checks';
import { IntegrityModificationLog } from './integrity.models';

export const integrityFeatureKey = 'integrity';

export interface State {
    reportConfigData: ReportConfigData | null;
    reportResult: ReportResult | null;
    isReportRunning: boolean;
    userUpdatesInProgress: string[];
    userUpdatesCompleted: string[];
    userUpdatesFailed: string[];
    integrityLogsPending: IntegrityModificationLog[];
    integrityLogs: IntegrityModificationLog[];
    isLoadingLogs: boolean;
}

export const initialState: State = {
    reportConfigData: null,
    reportResult: null,
    isReportRunning: false,
    userUpdatesInProgress: [],
    userUpdatesCompleted: [],
    userUpdatesFailed: [],
    integrityLogsPending: [],
    integrityLogs: [],
    isLoadingLogs: false
};

export const reducer = createReducer(
    initialState,
    on(integrityActions.runReport, (state) => ({ ...state, isReportRunning: true })),
    on(integrityActions.runReportSuccess, (state, action) => ({
        ...state,
        reportResult: action.report,
        isReportRunning: false
    })),
    on(integrityActions.loadReportConfigData, (state) => ({ ...state, reportConfigData: null })),
    on(integrityActions.loadReportConfigDataSuccess, (state, action) => ({
        ...state,
        reportConfigData: action.reportConfigData
    })),
    on(integrityActions.saveReportConfigDataSuccess, (state, action) => ({
        ...state,
        reportConfigData: action.reportConfigData
    })),
    on(settingsActions.loadIntegritySettingsSuccess, (state, action) => ({
        ...state,
        reportConfigData: action.integrity
    })),
    on(integrityActions.loadReportResultSuccess, (state, action) => ({
        ...state,
        reportResult: action.report
    })),
    on(integrityActions.usersUpdate, (state, action) => ({
        ...state,
        userUpdatesInProgress: action.usersAndModifications.map((m) => m.user.id)
    })),
    on(integrityActions.userUpdateSuccess, (state, action) => ({
        ...state,
        userUpdatesInProgress: state.userUpdatesInProgress.filter((id) => id !== action.userAndModifications.user.id),
        userUpdatesCompleted: [...state.userUpdatesCompleted, action.userAndModifications.user.id],
        integrityLogsPending: [
            ...state.integrityLogsPending,
            ...action.userAndModifications.modifications.map<IntegrityModificationLog>((c) => ({
                userId: action.userAndModifications.user.id,
                displayName: action.userAndModifications.user.displayName,
                fieldName: c.fieldName,
                newValue: c.newValue,
                oldValue: action.userAndModifications.user[c.fieldName]
            }))
        ]
    })),
    on(integrityActions.userUpdateFailure, (state, action) => ({
        ...state,
        userUpdatesInProgress: state.userUpdatesInProgress.filter((id) => id !== action.userAndModifications.user.id),
        userUpdatesFailed: [...state.userUpdatesFailed, action.userAndModifications.user.id]
    })),
    on(integrityActions.loadIntegrityLogsSuccess, (state, action) => ({
        ...state,
        integrityLogs: action.logs,
        isLoadingLogs: false
    })),
    on(integrityActions.loadIntegrityLogs, (state) => ({ ...state, isLoadingLogs: true })),
    on(integrityActions.loadIntegrityLogsFailure, (state) => ({ ...state, isLoadingLogs: false })),
    on(integrityActions.postIntegrityLogsSuccess, (state) => ({
        ...state,
        integrityLogsPending: []
    }))
);

export const integrityFeature = createFeature({
    name: integrityFeatureKey,
    reducer,
    extraSelectors: (baseSelectors) => {
        //console.warn("EXTRA SELECTORS FOR INTEGRITY");
        return {};
    }
});
