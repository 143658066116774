import { createFeature, createReducer, on } from '@ngrx/store';
import * as uiActions from './ui.actions';
import { DirectoryUser } from '../data/data.models';

export const uiFeatureKey = 'ui';

export interface State {
    isDrawerOpen: boolean;
    isAppEntryPointSearchPage: boolean;
    selectedUsers: DirectoryUser[];
    deepLink: string;
    hostedInTOC: boolean;
    hostedInSharePoint: boolean;
    editModeInSharePoint: boolean;
    sharePointStartingUrl: string;
    theme: 'light' | 'dark' | 'auto';
}

export const initialState: State = {
    isDrawerOpen: false,
    isAppEntryPointSearchPage: false,
    selectedUsers: [],
    deepLink: '',
    hostedInTOC: false,
    hostedInSharePoint: false,
    editModeInSharePoint: false,
    sharePointStartingUrl: '',
    theme: 'light'
};

export const reducer = createReducer(
    initialState,
    on(uiActions.drawerOpen, (state) => ({ ...state, isDrawerOpen: true })),
    on(uiActions.drawerClose, (state) => ({ ...state, isDrawerOpen: false })),
    on(uiActions.appEntryPointSearchPage, (state) => ({ ...state, isAppEntryPointSearchPage: true })),
    on(uiActions.selectDeselectUser, (state, action) => {
        const s = [...state.selectedUsers];
        const index = s.findIndex((u) => u.id === action.user.id);
        if (index === -1) {
            s.push(action.user);
        } else {
            s.splice(index, 1);
        }
        return { ...state, selectedUsers: s };
    }),
    on(uiActions.clearSelectedUsers, (state) => ({ ...state, selectedUsers: [] })),
    on(uiActions.openDeepLink, (state, action) => ({ ...state, deepLink: action.url })),
    on(uiActions.hostedInTOC, (state) => ({ ...state, hostedInTOC: true })),
    on(uiActions.hostedInSharePoint, (state) => ({ ...state, hostedInSharePoint: true })),
    on(uiActions.editModeInSharePoint, (state) => ({ ...state, editModeInSharePoint: true })),
    on(uiActions.setSharePointStartingUrl, (state, action) => ({ ...state, sharePointStartingUrl: action.url })),
    on(uiActions.setTheme, (state, action) => ({ ...state, theme: action.theme }))
);

// export const getIsDrawerOpen = (state: State) => state.isDrawerOpen;
// export const getIsAppEntryPointSearchPage = (state: State) => state.isAppEntryPointSearchPage;
// export const getSelectedUsers = (state: State) => state.selectedUsers;
// export const getDeepLink = (state: State) => state.deepLink;
// export const getHostedInTOC = (state: State) => state.hostedInTOC;
// export const getHostedInSharePoint = (state: State) => state.hostedInSharePoint;
// export const getEditModeInSharePoint = (state: State) => state.editModeInSharePoint;
// export const getSharePointStaringUrl = (state: State) => state.sharePointStartingUrl;
// export const getTheme = (state: State) => state.theme;

export const uiFeature = createFeature({
    name: uiFeatureKey,
    reducer,
    extraSelectors: () => ({})
});
