import { createAction, props } from '@ngrx/store';
import { ReportResult } from './checks/report-result';
import { ReportConfigData } from './checks';
import { DirectoryUserModification, IntegrityModificationLog, UserAndModifications } from './integrity.models';
import { DirectoryUser } from '../data/data.models';
import { SettingsServerResponse } from '../settings/settings.models';

export const loadReportConfigData = createAction('[Integrity] Load Report Config Data');
export const loadReportConfigDataSuccess = createAction(
    '[Integrity] Load Report Config Data Success',
    props<{ reportConfigData: ReportConfigData }>()
);
export const loadReportConfigDataFailure = createAction(
    '[Integrity] Load Report Config Data Failure',
    props<{ error: any }>()
);

export const saveReportConfigData = createAction(
    '[Integrity] Save Report Config Data',
    props<{ reportConfigData: ReportConfigData }>()
);
export const saveReportConfigDataSuccess = createAction(
    '[Integrity] Save Report Config Data Success',
    props<{ reportConfigData: ReportConfigData }>()
);
export const saveReportConfigDataFailure = createAction(
    '[Integrity] Save Report Config Data Failure',
    props<{ error: any }>()
);

export const runReport = createAction('[Integrity] Run Report');
export const runReportSuccess = createAction('[Integrity] Run Report Success', props<{ report: ReportResult }>());
export const runReportFailure = createAction('[Integrity] Run Report Failure', props<{ error: any }>());

export const loadReportResult = createAction('[Integrity] Load Report Result');
export const loadReportResultSuccess = createAction(
    '[Integrity] Load Report Result Success',
    props<{ report: ReportResult }>()
);
export const loadReportResultFailure = createAction('[Integrity] Load Report Result Failure', props<{ error: any }>());

export const saveUserModifications = createAction('[Integrity] Save User Modifications', props<{ modifications: DirectoryUserModification[] }>());
export const saveUserModificationsSuccess = createAction(
    '[Integrity] Save User Modifications Success',
    props<{ response: SettingsServerResponse }>()
);
export const saveUserModificationsFailure = createAction(
    '[Integrity] Save User Modifications Failure',
    props<{ error: any }>()
);

export const usersUpdate = createAction('[Integrity] Users Update', props<{ usersAndModifications: UserAndModifications[] }>());
export const usersUpdateFinished = createAction('[Integrity] Users Update Finished');
export const userUpdateSuccess = createAction('[Integrity] User Update Success', props<{ userAndModifications: UserAndModifications }>());
export const userUpdateFailure = createAction('[Integrity] User Update Failure', props<{ userAndModifications: UserAndModifications }>());

export const postIntegrityLogsSuccess = createAction('[Integrity] Post Integrity Logs Success');
export const postIntegrityLogsFailure = createAction('[Integrity] Post Integrity Logs Failure', props<{ error: any }>());

export const loadIntegrityLogs = createAction('[Integrity] Load Integrity Logs');
export const loadIntegrityLogsSuccess = createAction('[Integrity] Load Integrity Logs Success', props<{ logs: IntegrityModificationLog[] }>());
export const loadIntegrityLogsFailure = createAction('[Integrity] Load Integrity Logs Failure', props<{ error: any }>());

export const updateUserProfile = createAction('[Integrity] Update User Profile', props<{ userId: string, payload: Partial<DirectoryUser> }>());
export const updateUserProfileSuccess = createAction('[Integrity] Update User Profile Success');
export const updateUserProfileFailure = createAction('[Integrity] Update User Profile Failure', props<{ error: any }>());
