<div class="card mb-3 feature-consent flex-grow-1">
    <div class="card-header bg-light-dark">
        <h3 class="card-title">{{feature.displayName}}</h3>
    </div>
    <div class="card-body body-height">
        <div class="alert alert-warning" role="alert" *ngIf="isError">
            {{errorMessage}}
        </div>
        <div class="card-text">
            {{feature.description}}
        </div>
        <br />
        <div *ngIf="feature.globalAdmin" class="card-text">
            <b>Global Admin</b> - required for this feature.
        </div>
        <br />
        <div class="card-text">
            <b>Scopes required:</b>
        </div>
        <div class="d-flex flex-column">
            <div class="d-flex align-items-center py-2" *ngFor="let s of feature.scopes">
                <span class="bullet bg-primary me-3"></span> {{s}}
            </div>
        </div>
    </div>
    <div class="card-footer d-flex align-items-end">
        <div class="w-100">
            <div *ngIf="featureConsented" class="mb-10">
                <button class="btn btn-lg btn-success w-100 fw-bold" (click)="onUserConsent()"><i
                        class="bi bi-check text-white"></i>Consent granted</button>
            </div>
            <div>
                <button class="btn btn-lg btn-wide btn-primary w-100 fw-bold" (click)="onUserConsent()">Provide
                    Consent</button>
            </div>
        </div>
    </div>
</div>
