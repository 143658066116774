import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DirectoryUser } from '../../data/data.models';
import { GraphImageService } from '../../services/graph-image.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'dir-graph-picture',
    templateUrl: './graph-picture.component.html',
    styleUrls: ['./graph-picture.component.scss']
})
export class GraphPictureComponent implements OnInit, OnDestroy {
    private _directoryUser: DirectoryUser;

    @Input()
    get directoryUser(): DirectoryUser {
        return this._directoryUser;
    }

    set directoryUser(user: DirectoryUser) {
        this._directoryUser = user;
        this.loadDirectoryUserImage();
    }

    @Input() size = 35;
    @Input() classes = '';
    @Input() style: 'circle' | 'square' | '' = '';
    @Input() id = '';

    @Input() isCurrentUser: boolean = false;
    @Input() showDefaultPicture: boolean = false;

    userInitials = '';
    isImageLoading = false;
    showFallback = false;
    imageSrc: any;

    private unsubscribe$: Subject<void> = new Subject();
    constructor(private imageService: GraphImageService) {}

    ngOnInit(): void {
        this.isImageLoading = false;
        this.showFallback = false;

        if (this.id) {
            this.getImageFromService(this.id);
        }

        this.loadDirectoryUserImage();
    }

    private loadDirectoryUserImage() {
        if (this.directoryUser) {
            if (this.directoryUser.pictureUrl) {
                this.imageSrc = this.directoryUser.pictureUrl;
            } else {
                this.getImageFromService(this.directoryUser.id);
            }
            //combine first letters of first and last names into userInitials
            this.userInitials =
                this.directoryUser.firstName != null && this.directoryUser.firstName.trim().length !== 0
                    ? this.directoryUser.firstName[0]
                    : '';
            this.userInitials +=
                this.directoryUser.lastName != null && this.directoryUser.lastName.trim().length !== 0
                    ? this.directoryUser.lastName[0]
                    : '';
        }
    }

    getImageFromService(objectId: string) {
        this.isImageLoading = true;

        this.imageService
            .getImage(objectId, this.isCurrentUser)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (data) => {
                    if (data != null && data !== '') {
                        this.imageSrc = data;
                        this.isImageLoading = false;
                        this.showFallback = false;
                    } else {
                        this.isImageLoading = false;
                        this.showFallback = true;
                    }
                },
                error: (error) => {
                    this.isImageLoading = false;
                    this.showFallback = true;
                }
            });
    }

    getSize() {
        if (this.size == 35) {
            return 'symbol-sm-35px symbol-35px';
        } 
        return 'symbol-' + this.size + 'px';
    }

    createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener(
            'load',
            () => {
                this.imageSrc = reader.result;
            },
            false
        );

        if (image) {
            reader.readAsDataURL(image);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
