export class DemoHelper {
    public static ActivateDemoMode(): void {
        localStorage.setItem('isDemo', 'true');
        localStorage.setItem('isRebuildRequired', 'true');
        window.location.href = '/directory';
    }

    public static DeactivateDemoMode(): void {
        localStorage.setItem('isDemo', 'false');
        localStorage.setItem('isRebuildRequired', 'true');
        window.location.href = '/directory';
    }

    public static ToggleDemoMode(): void {
        if (DemoHelper.IsDemoMode()) {
            DemoHelper.DeactivateDemoMode();
        } else {
            DemoHelper.ActivateDemoMode();
        }
    }

    public static IsRebuildRequired(): boolean {
        return localStorage.getItem('isRebuildRequired') === 'true';
    }

    public static ClearRebuildRequired(): void {
        localStorage.removeItem('isRebuildRequired');
    }

    public static IsDemoMode(): boolean {
        return localStorage.getItem('isDemo') === 'true';
    }
}
