import { trigger, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category } from '../../../data/categories';

@Component({
    selector: 'dir-category-menu-item',
    templateUrl: './category-menu-item.component.html',
    styleUrls: ['./category-menu-item.component.scss'],
    animations: [
        // trigger('slideUpDown', [
        //     state('0', style({ 'max-height': '*', opacity: 1 })),
        //     state('1', style({ 'max-height': '0px', opacity: 0 })),
        //     transition(':enter', animate('1800ms ease-in-out')),
        //     transition('* => *', animate('1800ms ease-in-out')),
        //     transition('* => void', animate('1400ms ease-in-out'))
        // ]),
        trigger('slideDownUp', [
            // transition(':enter', [style({ height: 0 }), animate('300ms ease-out')]),
            // transition(':leave', [animate('300ms ease-out', style({ height: 0 }))])
            transition('void => clicked', [style({ height: 0 }), animate('300ms ease-out')]),
            transition('* => void', [animate('300ms ease-out', style({ height: 0 }))])
        ])
    ]
})
export class CategoryMenuItemComponent implements OnInit {
    @Input() category: Category;
    @Input() isExpanded = false;
    @Output() toggleExpanded = new EventEmitter<number>();
    clicked = '';
    constructor() {}

    ngOnInit(): void {}

    toggle($event: MouseEvent) {
        this.clicked = 'clicked';
        this.toggleExpanded.emit(this.category.id);
        $event.preventDefault();
        $event.stopPropagation();
        return false;
    }
}
