import { createReducer, on } from '@ngrx/store';
import { UserAvailability, UserPresence } from './availability.models';
import * as UserPresenceActions from './availability.actions';

export const userPresenceFeatureKey = 'userAvailability';

export interface AvailabilityState {
    userPresence: UserPresence[];
    userAvailability: UserAvailability[];
}

export const initialState: AvailabilityState = {
    userPresence: [],
    userAvailability: []
};

export const reducer = createReducer(
    initialState,
    on(UserPresenceActions.loadUserPresencesSuccess, (state, action) => {        
        const updatedPresences = [...state.userPresence];

        action.userPresences.forEach(presence => {
            const index = updatedPresences.findIndex(u => u.id === presence.id);
            if (index === -1) {
                updatedPresences.push(presence);
            } else {
                updatedPresences[index] = presence;
            }
        });
        return { ...state, userPresence: updatedPresences };
    }),

    on(UserPresenceActions.loadUserScheduleSuccess, (state, action) => {

        const updateSchedules = [...state.userAvailability];
        action.schedule.forEach(schedule => {
            const i = updateSchedules.findIndex(a => a.Mail === schedule.Mail);
            if (i === -1) {
                updateSchedules.push(schedule);
            } else {
                updateSchedules[i] = schedule;
            }
        });

        return { ...state, userAvailability: updateSchedules }
    })
);

export const selectUserPresences = (state: AvailabilityState) => state.userPresence;

export const selectUserPresence = (state: AvailabilityState, id: string) => {

    if (!state.userPresence || state.userPresence.length === 0) {
        return new UserPresence(id, 'Unknown', 'Unknown', null, new Date());
    }

    let i = state.userPresence.findIndex(presence => presence.id === id);
    return i >= 0 ? state.userPresence[i] : new UserPresence(id, 'Unknown', 'Unknown', null, new Date());
};

export const selectUserAvailability = (state: AvailabilityState) => state.userAvailability;




