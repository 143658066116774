import { DirectoryUser, SortField } from './data.models';

export function getSortFieldValue(user: DirectoryUser, sortField: SortField): string {
    switch (sortField) {
        case 'firstName':
            if (user.givenName != null && user.givenName.length > 0) {
                return user.givenName;
            }
            if (user.displayName != null) {
                const split = user.displayName.split(' ');
                if (split.length > 0) {
                    return split[0];
                }
            }
            return user.displayName;
        case 'lastName':
            if (user.surname != null && user.surname.length > 0) {
                return user.surname;
            }
            if (user.displayName != null) {
                const split = user.displayName.split(' ');
                if (split.length > 1) {
                    return split[1];
                }
            }
            return user.displayName;
        case 'city':
            return user.city;
        case 'jobTitle':
            return user.jobTitle;
        default:
            return user[sortField];
    }
}
