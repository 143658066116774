import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { SubscriptionStatus } from '../../auth/auth.models';
import * as fromRoot from '../../reducers';
import { BrowserService } from '../../services/browser-service';

@Component({
    selector: 'dir-admin-button',
    templateUrl: './admin-button.component.html',
    styleUrls: ['./admin-button.component.scss']
})
export class AdminButtonComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject();

    @Input() text: string;
    @Input() classes: string;
    @Input() disabled = false;
    @Input() hideToNonAdmin = true;

    @Output() clickOutput = new EventEmitter();

    isBlurred = false;
    subscriptionStatus: SubscriptionStatus = SubscriptionStatus.Subscribed;
    subscriptionStatus$: Observable<SubscriptionStatus>;
    isAdmin$: Observable<boolean>;
    isAdmin = false;

    isOnline = true;

    constructor(private store: Store, private browserService: BrowserService) {}

    ngOnInit(): void {
        this.subscriptionStatus$ = this.store.pipe(select(fromRoot.selectSubscriptionStatus));
        this.isAdmin$ = this.store.pipe(select(fromRoot.selectUserIsAdmin));

        combineLatest([this.subscriptionStatus$, this.isAdmin$])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([status, isAdmin]) => {
                this.subscriptionStatus = status;
                this.isAdmin = isAdmin;
            });

        this.browserService.isOnline$.pipe(takeUntil(this.unsubscribe$)).subscribe((online) => {
            this.isOnline = online;
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onButtonClick(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();
        if (this.isOnline) {
            this.clickOutput.emit(event);
        }
    }
}
