import { createAction, props } from "@ngrx/store";
import { UserAvailability, UserPresence } from "./availability.models";

export const loadUserPresences = createAction('[Availability] Load Presences', props<{ ids : string[] }>());
export const loadUserPresencesSuccess = createAction('[Availability] Load Presences Success', props<{ userPresences:UserPresence[]}>());
export const loadUserPresencesFailure = createAction('[Availability] Load Presences Failure', props<{ error: any }>());

export const loadUserSchedule = createAction('[Availability] Load Schedules', props<{ mail: string[], dayModifier : number, compareWithMe : boolean}>());
export const loadUserScheduleSuccess = createAction('[Availability] Load Schedules Success', props<{ schedule: UserAvailability[] }>());
export const loadUserSchedulesFailure = createAction('[Availability] Load Schedules Failure', props<{ error: any }>());

export const bookMeeting = createAction('[Availability] Book Meeting', props<{ mails: string[], startTime: string, duration:number}>());

