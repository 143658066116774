import appPackageJson from '../../package.json';
import { TDEnvironemnt } from './environment.type';

export const environment: TDEnvironemnt = {
    production: true,
    environmentName: 'test',
    debugDelays: false,
    clientId: '936d9398-7787-4953-9313-5a4564a77f60',
    redirectUri: 'https://testenv.team-directory.com/callback',
    redirectUriToc: 'https://testenv.team-directory.com/callbacktoc',
    redirectUriConsentResult: 'https://testenv.team-directory.com/consent-result',
    apiScope: 'api://9e033581-f98b-465b-ba4b-68d268a93871/access.server',
    serverUrl: 'https://testenv.team-directory.com',
    tocUrl: 'https://uat.myteamorgchart.com',
    apiScopeToc: 'api://uat.myteamorgchart.com/bb638fe1-bbbb-46d5-92a0-55ba875e868b/Team.Directory',
    appVersion: appPackageJson.version + '-test',
    skipWebSockets: true,
    appInsightsConnectionString:
        'InstrumentationKey=12ac3b89-ff5f-43db-97fb-c0a6f41b164e;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=2dfeee60-3844-470b-b633-17ba355a05de',
    adminConsentUrlGenerator: (tenantId: string, clientId: string, redirectUri: string, scope: string) =>
        `https://login.microsoftonline.com/${tenantId}/v2.0/adminconsent?client_id=${clientId}&redirect_uri=${encodeURIComponent(
            redirectUri
        )}&scope=${scope}`
};
