//Defines features used within TeamDirectory.
export interface FeatureDefinition {
    id: string;
    displayName: string;
    description: string;
    scopes: string[];
    globalAdmin: boolean;
}

export enum Feature {
    Directory = 'Directory',
    UserAvailability = 'Calendar',
    AllFeatures = 'AllFeatures',
    BasicAccess = 'BasicAccess',
    WriteAccess = 'WriteAccess'
}

export const Scopes = {
    DirectoryReadAll: 'Directory.Read.All',
    UserReadAll: 'User.Read.All',
    CalendarsRead: 'Calendars.Read',
    CalendarsReadShared : 'Calendars.Read.Shared',
    MailboxSettingsRead: 'MailboxSettings.Read',
    PresenceReadAll: 'Presence.Read.All',
    UserReadBasicAll: 'User.ReadBasic.All',
    UserRead : 'User.Read',
    UserWrite: 'User.ReadWrite',
    UserWriteAll: 'User.ReadWrite.All',
    PeopleRead :'People.Read'
};

// List of current features
export const FeatureDefinitions: Record<Feature, FeatureDefinition> = {
    Directory: {
        id: 'Directory',
        displayName: 'Full Directory',
        description: "View and search your organization's directory, including job titles, departments and pictures. ",
        globalAdmin: true,
        scopes: [Scopes.DirectoryReadAll, Scopes.UserReadAll]
    },
    BasicAccess: {
        id: 'BasicAccess',
        displayName: 'Minimal Directory',
        description: "Basic access to your organization's directory.  Only names and pictures will be available.",
        globalAdmin: false,
        scopes: [Scopes.UserReadBasicAll, Scopes.UserRead]
    },
    Calendar: {
        id: 'Calendar',
        displayName: 'User Availability',
        description: "Allow TeamDirectory to display user presence and calendar availability.",
        globalAdmin: false,
        scopes: [Scopes.CalendarsRead, Scopes.CalendarsReadShared, Scopes.MailboxSettingsRead, Scopes.PresenceReadAll]
    },
    AllFeatures: {
        id: 'AllFeatures',
        displayName: 'Grant all  Scopes',
        description: "Grant access to all features in TeamDirectory",
        globalAdmin: true,
        scopes: Object.values(Scopes)
    },
    WriteAccess: {
        id: 'WriteAccess',
        displayName: 'Write Access',
        description: "Allow TeamDirectory to write to your organization's directory.",
        globalAdmin: true,
        scopes: [Scopes.UserWriteAll]
    }
};
